import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Searches from './components/Searches';

const searches = (props,{ t }) => (
     
     <Container>
    <Searches {...props} />
    </Container>
     
  );

searches.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(searches);
