// OverallContext.js  
import React, { createContext, useState } from 'react';  

export const OverallContext = createContext();  

export const OverallProvider = ({ children }) => {  

     // function to handle click event of the first button  to enable and disable buttons
     const [isSearchButtonEnabled, setIsSearchButtonEnabled] = useState(false);  

   const enableSaveSearchButton = () => {  
    isSearchButtonEnabled?  setIsSearchButtonEnabled(true):setIsSearchButtonEnabled(true);   
  }; 


    return (  
        <OverallContext.Provider value={{isSearchButtonEnabled, setIsSearchButtonEnabled, enableSaveSearchButton }}>  
{ children }
        </OverallContext.Provider>  
    );  
};