import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Favourites from './components/Favourites';

const favourites = (props,{ t }) => (
  <Container>
    <Favourites {...props} />
  </Container>
);

favourites.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(favourites);
