import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ScheduleAppointments from './components/ScheduleAppointments';

const scheduleAppointments = (props, { t }) => (
    <Container>
        <ScheduleAppointments {...props} />
    </Container>
);

scheduleAppointments.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(scheduleAppointments);
